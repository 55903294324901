import {
  GET_LEADERBOARD_RESULT_LIST,
  GET_LEADERBOARD_RESULT_LIST_FAIL,
  GET_LEADERBOARD_RESULT_LIST_SUCCESS,
  GET_TDM_LEADERBOARD_RESULT_LIST,
  GET_TDM_LEADERBOARD_RESULT_LIST_FAIL,
  GET_TDM_LEADERBOARD_RESULT_LIST_SUCCESS,
} from "../actions";

const initialState = {
  fetchLoading: false,
  leaderboard: {},
  tdmleaderboard: {},

};

const leaderboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADERBOARD_RESULT_LIST:
      return { ...state, fetchLoading: true };
    case GET_LEADERBOARD_RESULT_LIST_SUCCESS:
      return { ...state, fetchLoading: false, leaderboard: action.payload };
    case GET_LEADERBOARD_RESULT_LIST_FAIL:
      return { ...state, fetchLoading: false, leaderboard: {} };

      case GET_TDM_LEADERBOARD_RESULT_LIST:
        return { ...state, fetchLoading: true };
      case GET_TDM_LEADERBOARD_RESULT_LIST_SUCCESS:
        return { ...state, fetchLoading: false, tdmleaderboard: action.payload };
      case GET_TDM_LEADERBOARD_RESULT_LIST_FAIL:
        return { ...state, fetchLoading: false, tdmleaderboard: {} };

        case GET_TDM_LEADERBOARD_RESULT_LIST:
          return { ...state, fetchLoading: true };
        case GET_TDM_LEADERBOARD_RESULT_LIST_SUCCESS:
          return { ...state, fetchLoading: false, tdmleaderboard: action.payload };
        case GET_TDM_LEADERBOARD_RESULT_LIST_FAIL:
          return { ...state, fetchLoading: false, tdmleaderboard: {} };

    default:
      return state;
  }
};

export default leaderboardReducer;
